<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12"><router-view></router-view></div>
        <div class="col-12 mb-4">
          <base-page-title title="Fuarlar"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="fair in fairs"
          :key="fair.title"
          class="col-lg-3 col-12 mb-3"
        >
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-name">
              <router-link
                :to="{
                  name: 'fair-detail',
                  params: {
                    id: fair.id,
                    parentSlug: fair.slug,
                    parentTitle: fair.title,
                    parentEditorData: fair.editorData,
                    parentImage: fair.image,
                  },
                }"
              >
                {{ fair.title }}
              </router-link>
            </div>
            <div class="item-value">{{ fair.keywords }}</div>
            <span @click="removeFair" :fairId="fair.id" class="remove-item">
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: ["id"],
  components: {
    BasePageTitle,
    BaseSpinner,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    fairs() {
      return this.$store.getters["fairs"];
    },
    ...mapGetters({
      token: "token",
    }),
  },

  mounted() {
    this.fetchFairs();
  },

  methods: {
    removeFair(event) {
      const fairId = event.target.getAttribute("fairId");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/page/remove/" + fairId,
        formData,
        {}
      );
    },

    async fetchFairs() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchFair");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: var(--link-color);

  &:hover {
    text-decoration: none;
  }
}
</style>
