<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Gönderilen E-Postalar"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="(email, index) in emails"
          :key="email.title"
          class="col-lg-3 col-12 mb-3"
        >
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-title">
              <router-link
                :to="{
                  name: 'news-detail',
                  params: {
                    id: email.id,
                    parentSlug: email.url,
                    parentTitle: email.title,
                    parentImage: email.image,
                    parentEditorData: email.content,
                    parentDescription: email.description,
                  },
                }"
              >
                {{ newss.title }}
              </router-link>
            </div>
            <span
              @click="removeEmail"
              :emailId="email.id"
              :index="index"
              class="remove-item"
            >
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePageTitle,
    BaseSpinner,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    emails() {
      return this.$store.getters["emails"];
    },
    ...mapGetters({
      token: "token",
    }),
  },

  mounted() {
    this.fetchEmails();
  },

  methods: {
    removeEmail(event) {
      const id = event.target.getAttribute("emailId");
      const index = event.target.getAttribute("index");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/email/remove/" + id,
        formData,
        {}
      );
      this.emails.splice(index, 1);
    },

    async fetchEmails() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchEmails");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
