<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Bankalar"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-for="bank in banks" :key="bank.id" class="col-lg-2 col-12 mb-3">
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-img is-equal-image">
              <img
                :src="'https://api.dessanayi.org/image/bank/' + bank.image"
              />
            </div>
            <span class="remove-item" :bankId="bank.id" @click="removeBank">
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePageTitle,
    BaseSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    banks() {
      return this.$store.getters["banks"];
    },
    ...mapGetters({
      token: "token",
    }),
  },
  mounted() {
    this.fetchBanks();
  },
  methods: {
    removeBank(event) {
      const bankId = event.target.getAttribute("bankId");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/config/bank/remove/" + bankId,
        formData,
        {}
      );
    },

    async fetchBanks() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchBank");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
