export default {
  async fetchServicesCompany(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    data.append(`pageType`, "SERVICES-COMPANY");
    const response = await fetch(`https://api.dessanayi.org/admin/page`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    const responseData = await response.json();

    const services_company = [];

    for (const key in responseData.data) {
      const service = {
        id: responseData.data[key]._id,
        title: responseData.data[key].title,
        editorData: responseData.data[key].content,
        slug: responseData.data[key].url,
        keyword: responseData.data[key].keyword,
        icon: responseData.data[key].icon,
        description: responseData.data[key].description,
        file: responseData.data[key].file,
      };
      services_company.push(service);
    }

    context.commit("fetchServicesCompany", services_company);

    console.log(token, responseData);
  },
};
