import { createStore } from "vuex";
import authModule from "./modules/auth/index";
import configModule from "./modules/config/index";
import menuModule from "./modules/menus/index";
import bankModule from "./modules/banks/index";
import bankNumberModule from "./modules/bank-number/index";
import phoneModule from "./modules/phones/index";
import slideModule from "./modules/slides/index";
import galleryModule from "./modules/gallery/index";
import activityModule from "./modules/services/index";
import activityCompanyModule from "./modules/services-company/index";
import newsModule from "./modules/news/index";
import listingModule from "./modules/listings/index";
import staffModule from "./modules/staffs/index";
import corporateModule from "./modules/corporate/index";
import gdprModule from "./modules/gdpr/index";
import fairModule from "./modules/fair/index";
import pageModule from "./modules/pages/index";
import securityModule from "./modules/security/index";
import popupModule from "./modules/popup/index";
import messageModule from "./modules/message/index";
import emailsModule from "./modules/emails/index";
import companyModule from "./modules/company/index";

const store = createStore({
  modules: {
    auth: authModule,
    config: configModule,
    menus: menuModule,
    banks: bankModule,
    bankNumber: bankNumberModule,
    phones: phoneModule,
    slides: slideModule,
    activity: activityModule,
    activityCompany: activityCompanyModule,
    news: newsModule,
    listings: listingModule,
    staff: staffModule,
    corporate: corporateModule,
    gdpr: gdprModule,
    fair: fairModule,
    pages: pageModule,
    security: securityModule,
    popup: popupModule,
    message: messageModule,
    emails: emailsModule,
    company: companyModule,
    gallery: galleryModule,
  },
});

export default store;
