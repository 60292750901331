<template>
  <header class="d-flex align-items-center">
    <div
      id="header-left"
      class="d-flex flex-column text-center justify-content-center h-100"
    >
      <p class="mb-0">Des Sanayi Sitesi</p>
      <span>Yönetim Paneli</span>
    </div>
    <div id="header-right" class="p-3 h-100">
      <div class="go-back" @click="goBack">Geri</div>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  display: inline-block;
  color: white;
  padding: 5px 10px;
  background: var(--primary-color);
  cursor: pointer;
  font-size: 0.7em;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: var(--header-height);
}
#header-left {
  width: var(--aside-width);
  background: var(--logo-bg);
  color: #ffffff70;

  span {
    font-size: 0.7em;
    display: block;
  }
}

#header-right {
  width: calc(100% - var(--aside-width));
}
</style>
