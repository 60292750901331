<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Menüler"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="(menu, index) in menus"
          :key="menu.id"
          class="col-12 col-xl-3"
        >
          <div v-if="!isLoading" class="item mb-4">
            <div class="item-title">
              <router-link
                :to="{
                  name: 'menu-detail',
                  params: {
                    id: menu.id,
                    parentSlug: menu.url,
                    parentTitle: menu.title,
                    parentSub: menu.title,
                  },
                }"
              >
                {{ menu.title }}
              </router-link>
            </div>
            <div class="item-value">
              <div v-if="menu.sub != '0'">
                <div
                  v-for="sub_menu in menu.sub"
                  :key="sub_menu.id"
                  class="d-flex"
                >
                  <router-link
                    :to="{
                      name: 'menu-detail',
                      params: {
                        id: sub_menu._id,
                        parentSlug: sub_menu.url,
                        parentTitle: sub_menu.title,
                        parentSub: sub_menu.sub,
                      },
                    }"
                  >
                    {{ sub_menu.title }}
                  </router-link>
                  <div
                    @click="removeSubMenu"
                    :subMenuId="sub_menu._id"
                    class="ml-1 mt-1 remove-item"
                  >
                    Sil
                  </div>
                </div>
              </div>
            </div>
            <div
              @click="removeMenu"
              :menuId="menu.id"
              :index="index"
              class="remove-item"
            >
              Sil
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";

export default {
  components: { BasePageTitle, BaseSpinner },
  data() {
    return {
      isLoading: false,
    };
  },

  created() {
    this.fetchMenu();
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),

    menus() {
      return this.$store.getters["menus"];
    },
  },

  methods: {
    async fetchMenu() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchMenu");
      } catch (error) {
        this.error = error.message || "something went wrong";
      }
      this.isLoading = false;
    },

    removeMenu(event) {
      const menuId = event.target.getAttribute("menuId");
      const index = event.target.getAttribute("index");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/menu/remove/" + menuId,
        formData,
        {}
      );
      this.menus.splice(index, 1);
    },

    removeSubMenu(event) {
      const subMenuId = event.target.getAttribute("subMenuId");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/menu/remove/" + subMenuId,
        formData,
        {}
      );
    },
  },
};
</script>
