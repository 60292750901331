<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Yönetim"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="staff in staffs"
          :key="staff.title"
          class="col-lg-3 col-12 mb-3"
        >
import UploadAdapter from "../../../../Shared/UploadAdapter";
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-name">{{ staff.title }}</div>
            <span @click="removeStaff" :newsId="staff.id" class="remove-item">
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePageTitle,
    BaseSpinner,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    staffs() {
      return this.$store.getters["staffs"];
    },
    ...mapGetters({
      token: "token",
    }),
  },

  mounted() {
    this.fetchStaff();
  },

  methods: {
    async fetchStaff() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchStaff");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },

    removeStaff(event) {
      const staffId = event.target.getAttribute("staffId");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/page/remove/" + staffId,
        formData,
        {}
      );
    },
  },
};
</script>

<style></style>
