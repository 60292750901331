<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="KVKK Düzenle"></base-page-title>
        </div>
        <form ref="form" @submit.prevent="onSubmit" class="w-100">
          <div class="col-12">
            <base-input
              id="title"
              mode="text"
              class="mb-3"
              v-model="title"
              label="Sayfa Başlığı"
            >
            </base-input>

            <base-input
              id="slug"
              mode="text"
              class="mb-3"
              v-model="slug"
              label="Sayfa URL eki"
            >
            </base-input>

            <ckeditor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"
            >
            </ckeditor>

            <dashboard
              ref="dash"
              :uppy="uppy"
              :props="{
                metaFields: [
                  { id: 'name', name: 'Name', placeholder: 'file name' },
                  {
                    id: 'caption',
                    name: 'Caption',
                    placeholder: 'describe what the image is about',
                  },
                ],
              }"
              :plugins="['ImageEditor']"
            />
            <div>
              <ul 
                 v-for="(file) in this.files"
                 :key="file"
              >
                  <li> {{file}} <label @click="removeFile" :fileValue="file" style="color: red; cursor: pointer"> (kaldır) </label></li>
              </ul>
            </div>
            <base-button class="mt-3" value="Kaydet"></base-button>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Faaliyet Güncellendi"
    >
    </base-notification>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BasePageTitle from "../../../components/UI/BasePageTitle";
import BaseButton from "../../../components/UI/BaseButton";
import BaseInput from "../../../components/UI/BaseInput";
import BaseNotification from "../../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";
import UploadAdapter from "../../../../Shared/UploadAdapter";

import { Dashboard } from "@uppy/vue";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
const uppy = new Uppy({
  meta: {
    token: "asddasd",
  },
});
uppy.use(XHRUpload, {
  endpoint: "https://api.dessanayi.org/admin/file/",
  fieldName: "files",
  formData: true,
});

export default {
  components: {
    BaseButton,
    BasePageTitle,
    BaseInput,
    BaseNotification,
    Dashboard,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    parentTitle: {
      type: String,
      required: false,
      default: null,
    },
    parentEditorData: {
      type: String,
      required: false,
      default: null,
    },
    parentSlug: {
      type: String,
      required: false,
      default: null,
    },
    parentImage: {
      type: String,
      required: false,
      default: null,
    },
    parentFiles: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      editor: ClassicEditor,
      title: null,
      editorData: null,
      slug: null,
      files: [],
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "imageUpload",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
          "Source",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        extraPlugins: [this.uploader],
        language: "tr",
      },
    };
  },

  mounted() {
    this.focusInput();

    this.uppy.on("upload-success", (file, response) => {
      response = response.body;
      if (response.status == "success") {
        this.files.push(response.data.name);
        console.log(this.files);
      }
    });
  },

  created() {
    this.title = this.parentTitle;
    this.editorData = this.parentEditorData;
    this.slug = this.parentSlug;
    this.files = this.parentFiles && this.parentFiles.length ? this.parentFiles : [];
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),

    uppy: () =>
      new Uppy({
        meta: {
          token: localStorage.token,
        },
      }).use(XHRUpload, {
        endpoint: "https://api.dessanayi.org/admin/file/",
        fieldName: "file",
      }),
  },

  beforeUnmount() {
    this.uppy.close();
  },
  methods: {
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },
    
    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("url", this.slug);
      formData.append("content", this.editorData);
      formData.append("visible", "on");
      formData.append("file", JSON.stringify(this.files));
      formData.append("pageType", "GDPR");

      await axios.post(
        `https://api.dessanayi.org/admin/page/update/` + this.id,
        formData,
        {}
      );

      this.isLoading = false;
    },

    removeFile(event) {
      this.files.splice(event.target.getAttribute("fileValue"), 1);
    },

    focusInput() {
      const inputs = this.$refs.form.querySelectorAll(
        'input:not([type="submit"])'
      );

      Array.from(inputs).map((e) => {
        if (e.value.length > 0) {
          e.previousElementSibling.classList.add("clicked-input");
        }
      });
    },
  },
};
</script>

<style></style>
