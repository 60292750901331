export default {
  async fetchPopup(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/popup`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();
    context.commit("fetchPopup", responseData.data);
  },
};
