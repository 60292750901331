export default {
  async fetchGdprItem(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    data.append(`pageType`, "GDPR");
    const response = await fetch(`https://api.dessanayi.org/admin/page/`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    const gdpr_items = [];

    for (const key in responseData.data) {
      const gdpr_item = {
        id: responseData.data[key]._id,
        title: responseData.data[key].title,
        url: responseData.data[key].url,
        content: responseData.data[key].content,
        image: responseData.data[key].image,
        files: responseData.data[key].file,
      };
      gdpr_items.push(gdpr_item);
    }

    context.commit("fetchGdprItem", gdpr_items);

    console.log(responseData);
  },
};
