export default {
  async fetchGallery(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/gallery`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    const galleries = [];

    for (const key in responseData.data) {
      galleries.push(responseData.data[key]);
    }
    context.commit("fetchGallery", galleries);
  },
};
