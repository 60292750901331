<template>
    <section class="page-container">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-4">
                    <base-page-title title="Hesap Numaraları"></base-page-title>
                </div>
                <div class="loading" v-if="isLoading">
                    <base-spinner></base-spinner>
                </div>
                <div
                    v-for="bank in banks"
                    :key="bank.id"
                    class="col-lg-6 col-12 mb-3"
                >
                    <div v-if="!isLoading" class="item text-center p-4">
                        <div class="item-img is-equal-image">
                            <img
                                :src="
                                    'https://api.dessanayi.org/image/bank_number/' +
                                        bank.image
                                "
                            />
                        </div>

                        <router-link
                            :to="{
                                name: 'bank-number-detail',
                                params: {
                                    id: bank._id,
                                },
                            }"
                        >
                            <div>
                                <span> Banka: {{ bank.bank }} </span> <br />
                                <span> Şube Kodu: {{ bank.branchCode }} </span>
                                <br />
                                <span>
                                    Hesap Numarası: {{ bank.accountNumber }}
                                </span>
                                <br />
                                <span> Alıcı: {{ bank.receiver }} </span> <br />
                                <span> IBAN: {{ bank.iban }} </span> <br />
                            </div>
                        </router-link>

                        <span
                            class="remove-item"
                            @click="removeBank($event, bank._id)"
                        >
                            Sil
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    components: {
        BasePageTitle,
        BaseSpinner,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        banks() {
            return this.$store.getters["bankNumber"];
        },
        ...mapGetters({
            token: "token",
        }),
    },
    mounted() {
        this.fetchBankNumber();
    },
    methods: {
        removeBank(event, bankId) {
            event.target.parentNode.parentNode.remove();
            const formData = new FormData();
            formData.append("token", this.token);
            axios.post(
                "https://api.dessanayi.org/admin/config/bank-number/remove/" +
                    bankId,
                formData,
                {}
            );
        },

        async fetchBankNumber() {
            this.isLoading = true;
            try {
                await this.$store.dispatch("fetchBankNumber");
            } catch (error) {
                this.error = error.message || "Bir şeyler yanlış gitti";
            }
            this.isLoading = false;
        },
    },
};
</script>

<style></style>
