<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Banka Ekle"></base-page-title>
        </div>
        <div class="col-12">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-12">
                <div class="image-upload-box">
                  <label for="bank-logo">Banka Logosu</label><br />
                  <input type="file" id="bank-logo" @change="onFileUpload" />
                </div>
              </div>
              <div class="col-12 mt-4">
                <base-input
                  id="bank"
                  mode="text"
                  class="mb-2"
                  v-model="title"
                  label="Banka Adı"
                >
                </base-input>
                <base-input
                  id="branchCode"
                  mode="text"
                  class="mb-2"
                  v-model="branchCode"
                  label="Şube Kodu"
                >
                </base-input>
                 <base-input
                  id="accountNumber"
                  mode="text"
                  class="mb-2"
                  v-model="accountNumber"
                  label="Hesap Numarası"
                >
                </base-input>
                <base-input
                  id="receiver"
                  mode="text"
                  class="mb-2"
                  v-model="receiver"
                  label="Alıcı"
                >
                </base-input>
                <base-input
                  id="iban"
                  mode="text"
                  class="mb-2"
                  v-model="iban"
                  label="IBAN"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 col-12 mt-4">
                <base-button value="Kaydet"></base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <base-notification v-if="itemSuccess" notificationMessage="Banka Eklendi">
    </base-notification>
  </section>
</template>

<script>
import BaseInput from "../../components/UI/BaseInput";
import BaseButton from "../../components/UI/BaseButton";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseNotification from "../../components/UI/BaseNotification";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseInput, BaseButton, BasePageTitle, BaseNotification },
  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      title: null,
      iban: null,
      FILE: null,
      receiver: null,
      branchCode: null,
      accountNumber: null,
    };
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
  },

  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("branchCode", this.branchCode);
      formData.append("accountNumber", this.accountNumber);
      formData.append("receiver", this.receiver);
      formData.append("iban", this.iban);
      formData.append("image", this.FILE);
      await axios
        .post("https://api.dessanayi.org/admin/config/bank-number/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
        });
      this.isLoading = false;
    },
  },
};
</script>
