<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Görsel Ekle"></base-page-title>
        </div>
        <div class="col-12">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-12">
                <div class="image-upload-box">
                  <label for="slide-img">Görsel</label><br />
                  <input type="file" id="slide-img" @change="onFileUpload" />
                </div>
              </div>
              <div class="col-xl-2 col-12 mt-4">
                <base-input
                  id="order"
                  mode="text"
                  class="mb-2"
                  v-model="order"
                  label="Görsel Sırası"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 col-12 mt-4">
                <base-button value="Kaydet"></base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <base-notification v-if="itemSuccess" notificationMessage="Görsel Eklendi">
    </base-notification>
  </section>
</template>

<script>
import BaseInput from "../../components/UI/BaseInput";
import BaseButton from "../../components/UI/BaseButton";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseNotification from "../../components/UI/BaseNotification";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseInput, BaseButton, BasePageTitle, BaseNotification },
  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      FILE: null,
      order: 0,
    };
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
  },

  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("image", this.FILE);
      formData.append("order", this.order);
      await axios
        .post("https://api.dessanayi.org/admin/gallery/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
        });
      this.isLoading = false;
    },
  },
};
</script>
