import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  popup() {
    return {
      popup: {},
    };
  },

  actions,
  mutations,
  getters,
};
