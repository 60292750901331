<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="E-Posta Oluştur"></base-page-title>
        </div>
        <form @submit.prevent="onSubmit" class="w-100">
          <div class="col-12 mb-4">
            <div class="image-upload-box">
              <label for="featured-image">E-Posta Görseli</label><br />
              <input type="file" id="featured-image" @change="onFileUpload" />
            </div>
          </div>
          <div class="col-12">
            <select v-model="category" class="mb-3">
              <option selected value="">Seçiniz...</option>
              <option value="107702899">Tümü</option>
              <option value="108570196">120 01 Sosyal Tesisler</option>
              <option value="108570202">120 02 Bankalar</option>
              <option value="108570205">120 03 Ticaret Merkezi</option>
              <option value="108570208">120 04 İş Merkezi</option>
              <option value="108570214">120 06 Site içi kooperatif kiracıları</option>
              <option value="108570217">120 11 Ortaklar</option>
              <option value="108570220">120 21 Ortak Kiracıları</option>
            </select>
            <base-input
              id="title"
              mode="text"
              class="mb-3"
              v-model="title"
              label="E-Posta Başlığı"
            >
            </base-input>
            <ckeditor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"
            >
            </ckeditor>
            <base-button class="mt-3" value="Kaydet"></base-button>
          </div>
        </form>
      </div>
    </div>
    <base-notification v-if="itemSuccess" notificationMessage="E-Posta Gönderildi">
    </base-notification>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseButton from "../../components/UI/BaseButton";
import BaseInput from "../../components/UI/BaseInput";
import BaseNotification from "../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseButton, BasePageTitle, BaseInput, BaseNotification },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      title: null,
      editor: ClassicEditor,
      editorData: null,
      category: null,
    };
  },
  computed: {
    ...mapGetters({
      token: "token",
    }),
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("content", this.editorData);
      formData.append("image", this.FILE);
      formData.append("category", this.category);

      await axios
        .post("https://api.dessanayi.org/admin/email/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
        });
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
