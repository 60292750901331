<template>
  <div id="accordion-menu">
    <div id="accordion-menu-wrapper">
      <div
        v-for="accordionItem in accordionItems"
        :key="accordionItem.accordionTitle"
        class="accordion-item"
      >
        <div class="accordion-title" @click="accordionHandle">
          <img src="./assets/images/dashboard.svg" alt="" />
          {{ accordionItem.accordionTitle }}
          <span class="accordion-minus">-</span>
          <span class="accordion-plus">+</span>
        </div>

        <ul class="accordion-links">
          <li
            v-for="accordionLink in accordionItem"
            :key="accordionLink.accordionUrlText"
          >
            <div
              v-for="accordionLinkItem in accordionLink"
              :key="accordionLinkItem.accordionUrlText"
            >
              <router-link
                v-if="accordionLinkItem.accordionUrlText"
                :to="accordionLinkItem.accordionUrl"
              >
                {{ accordionLinkItem.accordionUrlText }}
              </router-link>
              <div></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div @click="logout">Çıkış Yap</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accordionItems: [
        {
          accordionTitle: "Kurumsal",
          accordionLinks: [
            { accordionUrl: "/new-corporate", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/corporate-items", accordionUrlText: "Kurumsal" },
            { accordionUrl: "/timeline", accordionUrlText: "Tarihçe" },
            { accordionUrl: "/management", accordionUrlText: "Yönetim Kurulu" },
          ],
        },
        {
          accordionTitle: "İlanlar",
          accordionLinks: [
            { accordionUrl: "/new-listing", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/listings", accordionUrlText: "İlanlar" },
          ],
        },
        {
          accordionTitle: "Fuarlar",
          accordionLinks: [
            { accordionUrl: "/new-fair", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/fairs", accordionUrlText: "Fuarlar" },
          ],
        },
        {
          accordionTitle: "Site İçi Hizmetler",
          accordionLinks: [
            { accordionUrl: "/new-service", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/services", accordionUrlText: "Hizmetler" },
          ],
        },
        {
          accordionTitle: "İş Yeri İşlemleri",
          accordionLinks: [
            {
              accordionUrl: "/new-service-company",
              accordionUrlText: "Yeni Ekle",
            },
            { accordionUrl: "/services-company", accordionUrlText: "İşlemler" },
          ],
        },
        {
          accordionTitle: "Yönetim",
          accordionLinks: [
            { accordionUrl: "/new-staff", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/staffs", accordionUrlText: "Yönetim" },
          ],
        },
        {
          accordionTitle: "KVKK",
          accordionLinks: [
            { accordionUrl: "/new-gdpr", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/gdpr", accordionUrlText: "KVKK" },
          ],
        },
        {
          accordionTitle: "Haberler",
          accordionLinks: [
            { accordionUrl: "/new-news", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/news", accordionUrlText: "Haberler" },
          ],
        },
        {
          accordionTitle: "Menüler",
          accordionLinks: [
            { accordionUrl: "/new-menu", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/menus", accordionUrlText: "Menüler" },
          ],
        },

        {
          accordionTitle: "Slaytlar",
          accordionLinks: [
            { accordionUrl: "/new-slide", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/slides", accordionUrlText: "Slaytlar" },
          ],
        },

        {
          accordionTitle: "Galeri",
          accordionLinks: [
            { accordionUrl: "/new-gallery", accordionUrlText: "Yeni Ekle" },
            { accordionUrl: "/gallery", accordionUrlText: "Galeri" },
          ],
        },

        {
          accordionTitle: "Telefonlar",
          accordionLinks: [
            { accordionUrl: "/new-phone", accordionUrlText: "Yeni Telefon" },
            { accordionUrl: "/phones", accordionUrlText: "Telefonlar" },
          ],
        },
        {
          accordionTitle: "Bankalar",
          accordionLinks: [
            { accordionUrl: "/new-bank", accordionUrlText: "Yeni Banka" },
            { accordionUrl: "/banks", accordionUrlText: "Bankalar" },
          ],
        },
         {
          accordionTitle: "Banka Hesapları",
          accordionLinks: [
            { accordionUrl: "/new-bank-number", accordionUrlText: "Yeni Hesap Numarası" },
            { accordionUrl: "/bank-number", accordionUrlText: "Hesap Numaraları" },
          ],
        },
        {
          accordionTitle: "Popup",
          accordionLinks: [
            { accordionUrl: "/new-popup", accordionUrlText: "Popup" },
          ],
        },
        {
          accordionTitle: "İletişim",
          accordionLinks: [
            { accordionUrl: "/message", accordionUrlText: "Mesajlar" },
          ],
        },
        {
          accordionTitle: "Güvenlik",
          accordionLinks: [
            { accordionUrl: "/security", accordionUrlText: "Personeller" },
            {
              accordionUrl: "/new-security",
              accordionUrlText: "Personel Ekle",
            },
          ],
        },
        {
          accordionTitle: "Şirket Hesapları",
          accordionLinks: [
            { accordionUrl: "/company", accordionUrlText: "Şirketler" },
            {
              accordionUrl: "/new-company",
              accordionUrlText: "Hesap Ekle",
            },
          ],
        },
        {
          accordionTitle: "Toplu E-Posta",
          accordionLinks: [
            { accordionUrl: "/email-send", accordionUrlText: "Toplu Gönder" },
          ],
        },
        {
          accordionTitle: "Ayarlar",
          accordionLinks: [
            { accordionUrl: "/settings", accordionUrlText: "Genel Ayarlar" },
            { accordionUrl: "/", accordionUrlText: "" },
          ],
        },
      ],
    };
  },
  methods: {
    accordionHandle(event) {
      const accordionTitles = document.querySelectorAll(".accordion-title");
      const accordionTitlesArr = Array.from(accordionTitles);
      accordionTitlesArr.map((el) =>
        el.nextElementSibling.classList.remove("show-links")
      );
      accordionTitlesArr.map((el) => el.classList.remove("active-accordion"));
      event.target.classList.add("active-accordion");
      event.target.nextElementSibling.classList.add("show-links");
    },

    async logout() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("logout");
        this.$router.replace("/login");
      } catch (error) {
        this.error = error.message || "something went wrong";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
#accordion-menu {
  width: var(--aside-width);
  top: var(--header-height);
  height: calc(100% - var(--header-height));
  color: var(--light-blue);
  background: var(--dark-blue);
  position: fixed;
  padding: 25px 20px 20px 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#accordion-menu-wrapper {
  margin: auto 1em auto 0;
  overflow-y: scroll;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-item .accordion-minus {
  display: none;
}

.active-accordion .accordion-plus {
  display: none;
}
.active-accordion .accordion-minus {
  display: block;
}

.accordion-item ul {
  padding: 0 0 0 15px;
  list-style: none;
  border-left: 3px solid #a2aad6;
  margin-top: 10px;
  display: none;
}

.show-links {
  display: block !important;
}

.accordion-item ul a {
  font-size: 0.8em;
  text-decoration: none;
  color: #a2aad6;
}

.accordion-item .accordion-title {
  font-family: "Poppins";
  position: relative;
  cursor: pointer;
}

.accordion-item .accordion-title img {
  width: 15px;
  object-fit: contain;
  position: relative;
  top: -2px;
  left: -5px;
}
.accordion-item .accordion-title span {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
