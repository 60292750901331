<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Kurumsal"></base-page-title>
        </div>
        <form @submit.prevent="onSubmit" class="w-100">
          <div class="col-12">
            <base-input
              id="title"
              mode="text"
              class="mb-3"
              v-model="title"
              label="Sayfa Başlığı"
            >
            </base-input>
            <base-input
              id="url"
              mode="text"
              class="mb-3"
              v-model="url"
              label="URL"
            >
            </base-input>
            <ckeditor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"
            >
            </ckeditor>
            <base-input
              id="description"
              mode="textarea"
              class="my-3"
              v-model="description"
              label="Kısa Açıklama"
            >
            </base-input>
            <base-input
              id="keywords"
              mode="text"
              class="mb-3"
              v-model="keywords"
              label="Anahtar Kelimeler"
            >
            </base-input>
            <base-button class="mt-3" value="Kaydet"> </base-button>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Kurumsal Eklendi"
    >
    </base-notification>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";
import UploadAdapter from "../../../Shared/UploadAdapter";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseButton from "../../components/UI/BaseButton";
import BaseInput from "../../components/UI/BaseInput";
import BaseNotification from "../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseButton, BasePageTitle, BaseInput, BaseNotification },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      title: null,
      editor: ClassicEditor,
      editorData: null,
      keywords: null,
      description: null,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "imageUpload",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        extraPlugins: [this.uploader],
        language: "tr",
      },
    };
  },
  computed: {
    ...mapGetters({
      token: "token",
    }),
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },

    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("url", this.url);
      formData.append("description", this.description);
      formData.append("keyword", this.keywords);
      formData.append("visible", "on");
      formData.append("content", this.editorData);
      formData.append("pageType", "CORPORATE");
      await axios
        .post("https://api.dessanayi.org/admin/page/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
        });

      this.isLoading = false;
    },
  },
};
</script>

<style></style>
