<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Şirket Hesabı"></base-page-title>
        </div>
        <form ref="form" @submit.prevent="onSubmit" class="w-100">
          <div class="col-12">
            <base-input
              id="title"
              mode="text"
              class="mb-3"
              v-model="fullname"
              label="Şirket Adı"
            >
            </base-input>

            <base-input
              id="slug"
              mode="email"
              class="mb-3"
              v-model="email"
              label="E-Posta"
            >
            </base-input>

             <base-input
              id="slug"
              mode="text"
              class="mb-3"
              v-model="username"
              label="Kullanıcı Adı"
            >
            </base-input>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="İçerik Güncellendi"
    >
    </base-notification>
  </section>
</template>

<script>
import BasePageTitle from "../../../components/UI/BasePageTitle";
import BaseInput from "../../../components/UI/BaseInput";
import BaseNotification from "../../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePageTitle,
    BaseInput,
    BaseNotification,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    fullnameParent: {
      type: String,
      required: false,
      default: null,
    },
    emailParent: {
      type: String,
      required: false,
      default: null,
    },
    phoneParent: {
      type: String,
      required: false,
      default: null,
    },
    usernameParent: {
      type: String,
      required: false,
      default: null,
    },
  },

    data() {
    return {
      isLoading: false,
      itemSuccess: false,
    };
  },
  mounted() {
    this.focusInput();
  },

  created() {
    this.itemSuccess = false;
    this.fullname = this.fullnameParent;
    this.email = this.emailParent;
    this.phone = this.phoneParent;
    this.username = this.usernameParent;
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
  },

  beforeUnmount() {
  },

  methods: {
    async onSubmit() {
      this.isLoading = true;

      const formData = {};
      formData.token = this.token;
      formData.email = this.email;
      formData.username = this.username;
      formData.fullname = this.fullname;
      formData.phone = this.phone;
      await axios
        .post(
          `https://api.dessanayi.org/admin/security/update/` + this.id,
          formData,
          {}
        )
        .then((res) => {
          if(res.status == "success") {
            this.itemSuccess = true;
          }
        });

      this.isLoading = false;
    },

    focusInput() {
      const inputs = this.$refs.form.querySelectorAll(
        'input:not([type="submit"])'
      );

      Array.from(inputs).map((e) => {
        if (e.value.length > 0) {
          e.previousElementSibling.classList.add("clicked-input");
        }
      });
    },

    async fetchServices() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchServices");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
