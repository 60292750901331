<template>
    <section class="page-container">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-4">
                    <base-page-title title="Hizmet Düzenle"></base-page-title>
                </div>
                <form @submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-12">
                            <div class="image-upload-box">
                                <label for="bank-logo">Banka Logosu</label
                                ><br />
                                <input
                                    type="file"
                                    id="bank-logo"
                                    @change="onFileUpload"
                                />
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <base-input
                                id="bank"
                                mode="text"
                                class="mb-2"
                                v-model="bank"
                                label="Banka Adı"
                            >
                            </base-input>
                            <base-input
                                id="branchCode"
                                mode="text"
                                class="mb-2"
                                v-model="branchCode"
                                label="Şube Kodu"
                            >
                            </base-input>
                            <base-input
                                id="accountNumber"
                                mode="text"
                                class="mb-2"
                                v-model="accountNumber"
                                label="Hesap Numarası"
                            >
                            </base-input>
                            <base-input
                                id="receiver"
                                mode="text"
                                class="mb-2"
                                v-model="receiver"
                                label="Alıcı"
                            >
                            </base-input>
                            <base-input
                                id="iban"
                                mode="text"
                                class="mb-2"
                                v-model="iban"
                                label="IBAN"
                            >
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-2 col-12 mt-4">
                            <base-button value="Kaydet"></base-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <base-notification
            v-if="itemSuccess"
            notificationMessage="İçerik Güncellendi"
        >
        </base-notification>
    </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BasePageTitle from "../../../components/UI/BasePageTitle";
import BaseButton from "../../../components/UI/BaseButton";
import BaseInput from "../../../components/UI/BaseInput";
import BaseNotification from "../../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
    components: {
        BaseButton,
        BasePageTitle,
        BaseInput,
        BaseNotification,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            isLoading: false,
            itemSuccess: false,
            editor: ClassicEditor,
            title: null,
            editorData: null,
            slug: null,
            icon: null,
            order: null,

            accountNumber: "",
            bank: "",
            branchCode: "",
            iban: "",
            receiver: "",

            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "insertTable",
                    "|",
                    "imageUpload",
                    "mediaEmbed",
                    "|",
                    "undo",
                    "redo",
                ],
                table: {
                    toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
                },
                extraPlugins: [this.uploader],
                language: "tr",
            },
        };
    },

    async mounted() {
         await axios
                .post(
                    `https://api.dessanayi.org/admin/config/bank-number/` + this.id, {
                        token: this.token
                    }
                )
                .then((res) => {
                    this.accountNumber = res.data.data.accountNumber;
                    this.bank = res.data.data.bank;
                    this.branchCode = res.data.data.branchCode;
                    this.iban = res.data.data.iban;
                    this.receiver = res.data.data.receiver;
                });
    },

    created() {
        this.title = this.parentTitle;
        this.editorData = this.parentEditorData;
        this.slug = this.parentSlug;
        this.icon = this.parentIcon;
        this.files =
            this.parentFiles && this.parentFiles.length ? this.parentFiles : [];
    },

    computed: {
        ...mapGetters({
            token: "token",
        }),
    },
    methods: {
        onFileUpload(event) {
            this.FILE = event.target.files;
        },
        async onSubmit() {
            this.isLoading = true;

            const formData = new FormData();
            formData.append("accountNumber", this.accountNumber);
            formData.append("bank", this.bank);
            formData.append("branchCode", this.branchCode);
            formData.append("iban", this.iban);
            formData.append("receiver", this.receiver);
            formData.append("token", this.token);
            if (this.FILE && this.FILE.length) {
                formData.append("image", this.FILE[0]);
            }
            await axios
                .post(
                    `https://api.dessanayi.org/admin/config/bank-number/update/` + this.id,
                    formData,
                    {}
                )
                .then(() => {
                    this.itemSuccess = true;
                });

            this.isLoading = false;
        },
    },
};
</script>
