export default {
  async fetchSecurity(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/security`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    context.commit("fetchSecurity", responseData.data);

    console.log(token, responseData);
  },
};
