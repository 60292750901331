<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Sayfalar"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-for="menu in menus" :key="menu.id" class="col-12 col-xl-3">
          <div v-if="!isLoading" class="item mb-4">
            <div class="item-title">
              {{ menu.title }}<br /><small>{{ menu.id }}</small>
            </div>
            <div class="item-value">
              <div v-if="menu.sub != '0'">
                <div v-for="sub_menu in menu.sub" :key="sub_menu.id">
                  {{ sub_menu.title }}
                </div>
              </div>
            </div>
            <div @click="removeMenu" :menuId="menu.id" class="remove-item">
              Sil
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";

export default {
  components: { BasePageTitle, BaseSpinner },
  data() {
    return {
      isLoading: false,
    };
  },

  created() {
    this.fetchPagesForDelete();
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),

    menus() {
      return this.$store.getters["menus"];
    },
  },

  methods: {
    async fetchPagesForDelete() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchPagesForDelete");
      } catch (error) {
        this.error = error.message || "something went wrong";
      }
      this.isLoading = false;
    },

    removeMenu(event) {
      const menuId = event.target.getAttribute("menuId");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/page/remove/" + menuId,
        formData,
        {}
      );
    },
  },
};
</script>
