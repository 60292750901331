export default {
  async fetchPages(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/page/all/`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    const responseData = await response.json();

    const pages = [];

    for (const key in responseData.data) {
      const page = {
        id: responseData.data[key]._id,
        title: responseData.data[key].title,
        slug: responseData.data[key].url,
      };
      pages.push(page);
    }

    context.commit("fetchPages", pages);

    console.log(token, responseData);
  },
};
