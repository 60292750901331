<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <base-page-title title="Telefon Ekle"></base-page-title>
        </div>
        <div class="col-12">
          <form @submit.prevent="postPhone">
            <div class="row">
              <div class="col-12 mt-4">
                <select v-model="header" id="header">
                  <option value="Acil Durum">Acil Durum</option>
                  <option value="Resmi Kurumlar">Resmi Kurumlar</option>
                  <option value="Bankalar">Bankalar</option>
                  <option value="Gerekli Telefonlar">Gerekli Telefonlar</option>
                </select>
              </div>
              <div class="col-12 mt-4">
                <base-input
                  id="title"
                  mode="text"
                  class="mb-2"
                  v-model="title"
                  label="Başlık"
                >
                </base-input>
              </div>
              <div class="col-12 mt-4">
                <base-input
                  id="phone"
                  mode="text"
                  class="mb-2"
                  v-model="phone"
                  label="Telefon"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 col-12 mt-4">
                <base-button value="Kaydet"></base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <base-notification v-if="itemSuccess" notificationMessage="Telefon Eklendi">
    </base-notification>
  </section>
</template>

<script>
import BaseInput from "../../components/UI/BaseInput";
import BaseButton from "../../components/UI/BaseButton";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseNotification from "../../components/UI/BaseNotification";

import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: { BaseInput, BaseButton, BasePageTitle, BaseNotification },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
    };
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
  },

  methods: {
    async postPhone() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("header", this.header);
      formData.append("title", this.title);
      formData.append("phone", this.phone);
      formData.append("visible", "on");
      await axios
        .post("https://api.dessanayi.org/admin/config/phone/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
        });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-item {
  border: 1px solid #00000010;
  border-radius: 5px;
  padding: 5px;

  .phone-number {
    font-size: 0.7em;
  }
}
.remove-phone {
  font-size: 0.7em;
  color: red;
  cursor: pointer;
}
</style>
