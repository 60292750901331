<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Genel Ayarlar"></base-page-title>
        </div>
        <form ref="settingsForm" @submit.prevent="onSubmit">
          <div class="container py-3">
            <div class="row">
              <div class="col-10 mb-3">
                <div class="image-upload-box">
                  <label for="right-image">Sağ Görsel</label><br />
                  <input type="file" id="right-image" @change="onFileUpload" />
                  <img
                    id="right-image-file"
                    :src="'https://api.dessanayi.org/image/' + rightImage"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-2 mb-3">
                <div class="image-upload-box">
                  <label for="show-right-image">Sağ Görseli Göster</label><br />
                  <input
                    type="checkbox"
                    v-model="rightImageVisible"
                    id="show-right-image"
                  />
                </div>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="title"
                  mode="text"
                  class="mb-2"
                  v-model="title"
                  label="Site Başlığı"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="email"
                  mode="text"
                  class="mb-2"
                  v-model="email"
                  label="Email"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="address"
                  mode="text"
                  class="mb-2"
                  v-model="address"
                  label="Adres"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="phone"
                  mode="text"
                  class="mb-2"
                  v-model="phone"
                  label="Telefon"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="landPhone"
                  mode="text"
                  class="mb-2"
                  v-model="landPhone"
                  label="Sabit Telefon"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="whatsapp"
                  mode="text"
                  class="mb-2"
                  v-model="whatsapp"
                  label="Whatsapp"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="tagline"
                  mode="text"
                  class="mb-2"
                  v-model="tagline"
                  label="Etiketler"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="keyword"
                  mode="text"
                  class="mb-2"
                  v-model="keyword"
                  label="Anahtar Kelimeler"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="instagram"
                  mode="text"
                  class="mb-2"
                  v-model="instagram"
                  label="Instagram"
                >
                </base-input>
              </div>
              <div class="col-xl-4 mb-2">
                <base-input
                  id="linkedin"
                  mode="text"
                  class="mb-2"
                  v-model="linkedin"
                  label="Linkedin"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 d-flex justify-content-end">
                <base-button value="Kaydet" class="mt-3 w-100"> </base-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Ayarlar Güncellendi"
    ></base-notification>
  </section>
</template>

<script>
import BaseInput from "../components/UI/BaseInput";
import BaseButton from "../components/UI/BaseButton";
import BasePageTitle from "../components/UI/BasePageTitle";
import BaseNotification from "../components/UI/BaseNotification";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseInput, BaseButton, BasePageTitle, BaseNotification },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      title: this.$store.getters.config.title,
      email: this.$store.getters.config.email,
      address: this.$store.getters.config.address,
      phone: this.$store.getters.config.phone,
      landPhone: this.$store.getters.config.landPhone,
      whatsapp: this.$store.getters.config.whatsapp,
      tagline: this.$store.getters.config.tagline,
      keyword: this.$store.getters.config.keyword,
      instagram: this.$store.getters.config.instagram,
      linkedin: this.$store.getters.config.linkedin,
      rightImageVisible: this.$store.getters.config.rightImageVisible,
      rightImage: this.$store.getters.config.rightImage,
    };
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
  },

  mounted() {
    this.focusInput();
  },

  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

    focusInput() {
      const inputs = this.$refs.settingsForm.querySelectorAll(
        'input:not([type="submit"])'
      );

      Array.from(inputs).map((e) => {
        if (e.value.length > 0) {
          e.previousElementSibling.classList.add("clicked-input");
        }
      });
    },

    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("rightImage", this.FILE);
      formData.append("title", this.title);
      formData.append("email", this.email);
      formData.append("address", this.address);
      formData.append("phone", this.phone);
      formData.append("tagline", this.tagline);
      formData.append("keyword", this.keyword);
      formData.append("phone_land", this.landPhone);
      formData.append("instagram", this.instagram);
      formData.append("linkedin", this.linkedin);
      formData.append("phone_whatsapp", this.whatsapp);
      formData.append(
        "rightImageVisible",
        this.rightImageVisible ? "on" : "off"
      );
      await axios
        .post("https://api.dessanayi.org/admin/config/update", formData, {})
        .then((res) => {
          this.itemSuccess = true;
          console.log(res);
        });
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
#right-image-file {
  position: absolute;
  right: 40px;
  top: 0;
  height: 90%;
}
</style>
