<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="İlanlar"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="(message, index) in messages"
          :key="message._id"
          class="col-lg-3 col-12 mb-3"
        >
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-img is-equal-image">
              <router-link
                :to="{
                  name: 'message-detail',
                  params: {
                    id: message._id,
                    parentContent: message.content,
                    parentEmail: message.email,
                    parentPhone: message.phone,
                    parentTime: message.registerTime,
                    parentFullname: message.fullname,
                  },
                }"
              >
                {{ message.fullname }}
                <br>
                {{ message.email }}
                <br>
                {{ message.registertime }}
              </router-link>
            </div>
            <span
              class="remove-item"
              :listingId="message._id"
              :index="index"
              @click="removeMessage"
            >
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePageTitle,
    BaseSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    messages() {
      return this.$store.getters["message"];
    },
    ...mapGetters({
      token: "token",
    }),
  },
  mounted() {
    this.fetchMessage();
  },
  methods: {
    removeMessage(event) {
      const listingId = event.target.getAttribute("listingId");
      const index = event.target.getAttribute("index");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/contact/remove/" + listingId,
        formData,
        {}
      );
      this.messages.splice(index, 1);
    },

    async fetchMessage() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchMessage");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
