<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="İlan Ekle"></base-page-title>
        </div>

        <div class="col-12">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-12">
                <div class="image-upload-box">
                  <label for="featured-image">Öne Çıkan Görsel</label><br />
                  <input
                    type="file"
                    id="featured-image"
                    @change="onFileUpload"
                  />
                </div>
              </div>
              <div class="col-12 mt-4">
                <base-input
                  id="title"
                  mode="text"
                  class="mb-2"
                  v-model="title"
                  label="İlan Başlığı"
                >
                </base-input>
              </div>
              <div class="col-12">
                <label for="advert-type">Kategori</label>
                <select v-model="advertType" id="advert-type">
                  <option value="">Seçiniz</option>
                  <option value="Kiralık">Kiralık</option>
                  <option value="Satılık">Satılık</option>
                  <option value="İş İlanı">İş İlanı</option>
                </select>
              </div>

              <div class="col-12 mt-4">
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                >
                </ckeditor>
                <base-input
                  id="description"
                  mode="textarea"
                  class="my-3"
                  v-model="description"
                  label="Kısa Açıklama"
                >
                </base-input>
                <base-input
                  id="keywords"
                  mode="text"
                  class="mb-3"
                  v-model="keywords"
                  label="Anahtar Kelimeler"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 col-12 mt-4">
                <base-button value="Kaydet"></base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <base-notification v-if="itemSuccess" notificationMessage="İlan Eklendi">
    </base-notification>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BaseInput from "../../components/UI/BaseInput";
import BaseButton from "../../components/UI/BaseButton";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseNotification from "../../components/UI/BaseNotification";
import axios from "axios";
import { mapGetters } from "vuex";
import UploadAdapter from "../../../Shared/UploadAdapter";

export default {
  components: { BaseInput, BaseButton, BasePageTitle, BaseNotification },
  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      title: null,
      advertType: null,
      editor: ClassicEditor,
      editorData: null,
      keywords: null,
      description: null,
      FILE: null,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "imageUpload",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        extraPlugins: [this.uploader],
        language: "tr",
      },
    };
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
  },

  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

  uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },
    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("image", this.FILE);
      formData.append("advertType", this.advertType);
      formData.append("content", this.editorData);
      formData.append("keyword", this.keywords);
      formData.append("visible", "on");
      formData.append("pageType", "LISTING");
      await axios
        .post("https://api.dessanayi.org/admin/page/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
          this.title = "";
          this.editorData = "";
          this.description = "";
          this.keywords = "";
        });

      Array.from(document.querySelectorAll("label")).map((e) => {
        e.classList.remove("clicked-input");
      });
      this.isLoading = false;
    },
  },
};
</script>
