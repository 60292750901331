export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
    });
  },

  async auth(context, payload) {
    let url = "https://api.dessanayi.org/login/admin";
    let data = new URLSearchParams();
    data.append(`username`, payload.username);
    data.append(`password`, payload.password);
    const response = await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    localStorage.setItem("token", responseData.data);

    context.commit("setToken", {
      token: responseData.data,
    });

    if (!responseData.data) {
      const error = new Error(responseData.message || "Hata oluştu");
      throw error;
    }

    console.log(responseData.data);
  },

  tryLogin(context) {
    const token = localStorage.getItem("token");

    if (token) {
      context.commit("setToken", {
        token: token,
      });
    }
  },

  logout(context) {
    const token = localStorage.getItem("token");
    if (token) {
      context.commit("destroyToken");
    }
  },

  async slideAll(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/slide/`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();
    console.log(token, responseData);
  },
};
