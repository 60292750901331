export default {
  async fetchMenu(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/menu `, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    const menus = [];

    for (const key in responseData.data) {
      const menu = {
        id: responseData.data[key]._id,
        title: responseData.data[key].title,
        url: responseData.data[key].url,
        image: responseData.data[key].image,
        sub: responseData.data[key].sub,
      };
      menus.push(menu);
    }

    context.commit("fetchMenu", menus);

    console.log(token, responseData);
  },

  async fetchPagesForDelete(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/page/all/`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    const menus = [];

    for (const key in responseData.data) {
      const menu = {
        id: responseData.data[key]._id,
        title: responseData.data[key].title,
        url: responseData.data[key].url,
        image: responseData.data[key].image,
        sub: responseData.data[key].sub,
      };
      menus.push(menu);
    }

    context.commit("fetchMenu", menus);

    // console.log(token, responseData);
  },

  async postMenu(context, payload) {
    const token = context.rootGetters.token;

    let data = {
      token: token,
      title: payload.title,
      url: payload.url,
      page: payload.page,
    };
    console.log(data);

    data = new URLSearchParams(data).toString();

    const response = await fetch(`https://api.dessanayi.org/admin/menu/add`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    console.log("postMenu", responseData, data);
  },
};
