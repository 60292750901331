export default {
  async fetchConfig(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/config`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();
    // console.log(responseData);

    const configItem = {
      id: responseData.data.id,
      title: responseData.data.title,
      tagline: responseData.data.tagline,
      keyword: responseData.data.keyword,
      description: responseData.data.description,
      email: responseData.data.email,
      address: responseData.data.address,
      map: responseData.data.map,
      phone: responseData.data.phone,
      landPhone: responseData.data.phone_land,
      whatsapp: responseData.data.phone_whatsapp,
      twitter: responseData.data.twitter,
      facebook: responseData.data.facebook,
      instagram: responseData.data.instagram,
      linkedin: responseData.data.linkedin,
      logo: responseData.data.logo,
      favicon: responseData.data.favicon,
      rightImage: responseData.data.rightImage,
      rightImageVisible: responseData.data.rightImageVisible,
    };

    context.commit("fetchConfig", configItem);
  },
};
