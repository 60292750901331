<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12"><router-view></router-view></div>
        <div class="col-12 mb-4">
          <base-page-title title="Hizmetler"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="(service, index) in services"
          :key="service.title"
          class="col-lg-4 col-12 mb-3"
        >
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-name">
              <router-link
                :to="{
                  name: 'service-detail',
                  params: {
                    id: service.id,
                    parentSlug: service.slug,
                    parentTitle: service.title,
                    parentEditorData: service.editorData,
                    parentImage: service.image,
                    parentFiles: service.file
                  },
                }"
              >
                {{ service.title }}
              </router-link>
            </div>
            <div class="item-value">{{ service.keywords }}</div>
            <span
              @click="removeService"
              :serviceId="service.id"
              :index="index"
              class="remove-item"
            >
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: ["id"],
  components: {
    BasePageTitle,
    BaseSpinner,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    services() {
      return this.$store.getters["services"];
    },
    ...mapGetters({
      token: "token",
    }),
  },

  mounted() {
    this.fetchServices();
  },

  methods: {
    removeService(event) {
      const serviceId = event.target.getAttribute("serviceId");
      const index = event.target.getAttribute("index");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/page/remove/" + serviceId,
        formData,
        {}
      );
      this.services.splice(index, 1);
    },

    async fetchServices() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchServices");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: var(--link-color);

  &:hover {
    text-decoration: none;
  }
}
</style>
