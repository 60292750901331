export default {
  async fetchPhones(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(
      `https://api.dessanayi.org/admin/config/phone`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    const responseData = await response.json();

    const phones = [];

    for (const key in responseData.data.phone) {
      const phone = {
        id: responseData.data.phone[key]._id,
        header: responseData.data.phone[key].header,
        title: responseData.data.phone[key].title,
        phone: responseData.data.phone[key].phone,
      };
      phones.push(phone);
    }

    context.commit("fetchPhones", phones);

    console.log(token, responseData);
  },
};
