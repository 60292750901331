<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Popup Güncelle"></base-page-title>
        </div>
        <div class="col-12">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-12 mt-4">
                <base-input
                  id="title"
                  mode="text"
                  class="mb-2"
                  v-model="title"
                  label="Başlık"
                >
                </base-input>
              </div>
              <div class="col-12 mt-4">
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                >
                </ckeditor>
              </div>
              <div class="col-1 mt-4">
                 <div class="image-upload-box">
                  <label for="visible">Aktif</label><br />
                  <input
                    type="checkbox"
                    v-model="visible"
                    :checked="visible"
                    id="visible"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 col-12 mt-4">
                <base-button value="Kaydet"></base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <base-notification v-if="itemSuccess" notificationMessage="Popup Eklendi">
    </base-notification>
  </section>
</template>

<script>
import BaseInput from "../../components/UI/BaseInput";
import BaseButton from "../../components/UI/BaseButton";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseNotification from "../../components/UI/BaseNotification";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";
import UploadAdapter from "../../../Shared/UploadAdapter";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseInput, BaseButton, BasePageTitle, BaseNotification },
  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      title: null,
      FILE: null,
      editor: ClassicEditor,
      editorData: null,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "imageUpload",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        extraPlugins: [this.uploader],
        language: "tr",
      },
    };
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),

    popup() {
      return this.$store.getters["popup"];
    },
  },

  async mounted() {
    await this.fetchPopup();
    this.title = this.$store.getters.popup.title;
    this.editorData = this.$store.getters.popup.content;
    this.visible = this.$store.getters.popup.visible;
  },

  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

    data() {
      return {
        title: this.$store.getters.popup.title,
        editorData: this.$store.getters.popup.content,
        visible: this.$store.getters.popup.visible,
      };
    },

    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },

     async fetchPopup() {

      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchPopup");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },


    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("content", this.editorData);
      formData.append("visible", this.visible ? "on" : "off");
      await axios
        .post("https://api.dessanayi.org/admin/popup/update", formData, {})
        .then(() => {
          this.itemSuccess = true;
        });
      this.isLoading = false;
    },
  },
};
</script>
