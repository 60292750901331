<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Menü Ekle"></base-page-title>
        </div>
        <form ref="Form" @submit.prevent="postMenu">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-4">
                <base-input
                  id="title"
                  mode="text"
                  class="mb-2"
                  v-model="title"
                  label="Başlık"
                >
                </base-input>
              </div>
              <div class="col-10 mb-4">
                <base-input
                  id="url"
                  mode="text"
                  class="mb-2"
                  v-model="url"
                  label="URL"
                >
                </base-input>
              </div>
              <div class="col-2 mb-4">
                <base-input
                  id="order"
                  mode="text"
                  class="mb-2"
                  v-model="order"
                  label="Menü Sırası"
                >
                </base-input>
              </div>
              <div class="col-6 mb-4">
                <label for="sub">Üst Menü</label>
                <select v-model="sub" id="sub">
                  <option value="">Seçiniz</option>
                  <option v-for="menu in menus" :key="menu.id" :value="menu.id">
                    {{ menu.title }}
                  </option>
                </select>
              </div>
              <div class="col-6 mb-4">
                <label for="pages">Sayfalar</label>
                <select v-model="page" id="pages">
                  <option value="">Seçiniz</option>
                  <option
                    v-for="page in pages"
                    :key="page.id"
                    :value="page.title"
                    >{{ page.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 col-12">
                <base-button value="Kaydet"></base-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <base-notification v-if="itemSuccess" notificationMessage="Menü Eklendi">
    </base-notification>
  </section>
</template>

<script>
import BaseInput from "../../components/UI/BaseInput";
import BaseButton from "../../components/UI/BaseButton";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseNotification from "../../components/UI/BaseNotification";

import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: { BaseInput, BaseButton, BasePageTitle, BaseNotification },
  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      title: "",
      url: "",
      page: "",
      sub: "",
    };
  },
  created() {
    this.fetchPages();
    this.fetchMenu();
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),

    pages() {
      return this.$store.getters["pages"];
    },

    menus() {
      return this.$store.getters["menus"];
    },
  },

  methods: {
    async postMenu() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("url", this.url);
      formData.append("sub", this.sub);
      formData.append("order", this.order);
      formData.append("page", this.page);
      formData.append("visible", "on");
      await axios
        .post("https://api.dessanayi.org/admin/menu/add", formData, {})
        .then(() => {
          this.title = "";
          this.url = "";
          this.page = "";
          this.itemSuccess = true;
        });
      this.isLoading = false;
      Array.from(document.querySelectorAll("label")).map((e) => {
        e.classList.remove("clicked-input");
      });
    },

    async fetchPages() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchPages");
      } catch (error) {
        this.error = error.message || "something went wrong";
      }
      this.isLoading = false;
    },

    async fetchMenu() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchMenu");
      } catch (error) {
        this.error = error.message || "something went wrong";
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
select {
  width: 100%;
}
</style>
