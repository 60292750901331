<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Haberi Düzenle"></base-page-title>
        </div>
        <form ref="form" @submit.prevent="onSubmit" class="w-100">
          <div class="col-12 mb-4">
            <div class="image-upload-box">
              <label for="activity-icon">İlan Görseli</label><br />
              <input type="file" id="activity-icon" @change="onFileUpload" />
            </div>
          </div>
          <div class="col-12">
            <base-input
              id="title"
              mode="text"
              class="mb-3"
              v-model="title"
              label="Sayfa Başlığı"
            >
            </base-input>

            <base-input
              id="slug"
              mode="text"
              class="mb-3"
              v-model="slug"
              label="Sayfa URL eki"
            >
            </base-input>
            <ckeditor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"
            >
            </ckeditor>
            <base-input
              id="description"
              mode="textarea"
              class="my-3"
              v-model="description"
              label="Kısa Açıklama"
            >
            </base-input>
            <base-button class="mt-3" value="Kaydet"></base-button>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Faaliyet Güncellendi"
    >
    </base-notification>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BasePageTitle from "../../../components/UI/BasePageTitle";
import BaseButton from "../../../components/UI/BaseButton";
import BaseInput from "../../../components/UI/BaseInput";
import BaseNotification from "../../../components/UI/BaseNotification";
import UploadAdapter from "../../../../Shared/UploadAdapter";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseButton, BasePageTitle, BaseInput, BaseNotification },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    parentTitle: {
      type: String,
      required: false,
      default: null,
    },
    parentEditorData: {
      type: String,
      required: false,
      default: null,
    },
    parentDescription: {
      type: String,
      required: false,
      default: null,
    },
    parentSlug: {
      type: String,
      required: false,
      default: null,
    },
    parentImage: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      editor: ClassicEditor,
      title: null,
      image: null,
      editorData: null,
      slug: null,
      description: null,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "imageUpload",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        extraPlugins: [this.uploader],
        language: "tr",
      },
    };
  },

  mounted() {
    this.focusInput();
  },

  created() {
    this.title = this.parentTitle;
    this.editorData = this.parentEditorData;
    this.slug = this.parentSlug;
    this.description = this.parentDescription;
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
  },

  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files;
    },

    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("keyword", this.keyword);
      formData.append("visible", "on");
      formData.append("content", this.editorData);
      formData.append("url", this.slug);
      if(this.FILE && this.FILE.length) {
        formData.append("image", this.FILE[0]);
      }
      formData.append("pageType", "NEWS");
      await axios.post(
        `https://api.dessanayi.org/admin/page/update/` + this.id,
        formData,
        {}
      );

      this.isLoading = false;
    },

     uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },

    focusInput() {
      const inputs = this.$refs.form.querySelectorAll(
        'input:not([type="submit"])'
      );

      Array.from(inputs).map((e) => {
        if (e.value.length > 0) {
          e.previousElementSibling.classList.add("clicked-input");
        }
      });
    },
  },
};
</script>

<style></style>
