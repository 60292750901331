export default {
  async fetchSlide(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(`https://api.dessanayi.org/admin/slide`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    const slides = [];

    for (const key in responseData.data) {
      const slide = {
        id: responseData.data[key]._id,
        title: responseData.data[key].title,
        url: responseData.data[key].link,
        image: responseData.data[key].image,
        order: responseData.data[key].order,
      };
      slides.push(slide);
    }

    context.commit("fetchSlide", slides);

    console.log(token, responseData);
  },
};
