<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Menü Düzenle"></base-page-title>
        </div>
        <form
          ref="form"
          @submit.prevent="onSubmit"
          class="w-100 d-flex flex-wrap"
        >
          <div class="col-12">
            <base-input
              id="title"
              mode="text"
              class="mb-3"
              v-model="title"
              label="Sayfa Başlığı"
            >
            </base-input>
          </div>
          <div class="col-12">
            <base-input
              id="slug"
              mode="text"
              class="mb-3"
              v-model="slug"
              label="Sayfa URL eki"
            >
            </base-input>
          </div>
          <div class="col-6 mb-4">
            <label for="sub">Üst Menü</label>
            <select v-model="sub" id="sub">
              <option value="">Seçiniz</option>
              <option :selected="menu.id == this.sub" v-for="menu in menus" :key="menu.id" :value="menu.id">
                {{ menu.title }}
              </option>
            </select>
          </div>
          <div class="col-6 mb-4">
            <label for="pages">Sayfalar</label>
            <select v-model="page" id="pages">
              <option value="">Seçiniz</option>
              <option v-for="page in pages" :key="page.id" :value="page.title"
                >{{ page.title }}
              </option>
            </select>
          </div>
          <div class="col-12">
            <base-button class="mt-3" value="Kaydet"> </base-button>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Menü Güncellendi"
    >
    </base-notification>
  </section>
</template>

<script>
import BasePageTitle from "../../../components/UI/BasePageTitle";
import BaseButton from "../../../components/UI/BaseButton";
import BaseInput from "../../../components/UI/BaseInput";
import BaseNotification from "../../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseButton, BasePageTitle, BaseInput, BaseNotification },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    parentTitle: {
      type: String,
      required: false,
      default: null,
    },
    parentSlug: {
      type: String,
      required: false,
      default: null,
    },
    parentSub: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      title: null,
      slug: null,
    };
  },

  mounted() {
    this.focusInput();
  },

  created() {
    this.title = this.parentTitle;
    this.slug = this.parentSlug;
    this.sub = this.parentSub;
    console.log(this.sub)
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),

    pages() {
      return this.$store.getters["pages"];
    },

    menus() {
      return this.$store.getters["menus"];
    },
  },

  methods: {
    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("visible", "on");
      formData.append("url", this.slug);
      await axios
        .post(
          `https://api.dessanayi.org/admin/menu/update/` + this.id,
          formData,
          {}
        )
        .then(() => {
          this.itemSuccess = true;
        });

      this.isLoading = false;
    },

    async fetchPages() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchPages");
      } catch (error) {
        this.error = error.message || "something went wrong";
      }
      this.isLoading = false;
    },

    async fetchMenu() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchMenu");
      } catch (error) {
        this.error = error.message || "something went wrong";
      }
      this.isLoading = false;
    },

    focusInput() {
      const inputs = this.$refs.form.querySelectorAll(
        'input:not([type="submit"])'
      );

      Array.from(inputs).map((e) => {
        if (e.value.length > 0) {
          e.previousElementSibling.classList.add("clicked-input");
        }
      });
    },
  },
};
</script>

<style></style>
