<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Kişi Ekle"></base-page-title>
        </div>
        <form @submit.prevent="onSubmit" class="w-100">
          <div class="col-12">
            <base-input
              id="name"
              mode="text"
              class="mb-3"
              v-model="name"
              label="Kişi Adı"
            >
            </base-input>
            <base-input
              id="title"
              mode="textarea"
              class="my-3"
              v-model="title"
              label="Görevi"
            >
            </base-input>
            <base-button class="mt-3" value="Kaydet"> </base-button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseButton from "../../components/UI/BaseButton";
import BaseInput from "../../components/UI/BaseInput";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseButton, BasePageTitle, BaseInput },

  data() {
    return {
      name: null,
      title: null,
    };
  },
  computed: {
    ...mapGetters({
      token: "token",
    }),
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

    onSubmit() {
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("name", this.name);
      formData.append("title", this.title);
      formData.append("image", this.FILE);
      formData.append("pageType", "STAFF");
      axios.post("https://api.dessanayi.org/admin/page/add", formData, {});
    },
  },
};
</script>

<style></style>
