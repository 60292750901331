import { createApp } from "vue";

// Import Vuex store
import store from "./store/index";

// Import Vue Router
import { createRouter, createWebHistory } from "vue-router";

//Import CK Editor
import CKEditor from "@ckeditor/ckeditor5-vue";

// Import general app screen
import App from "./App.vue";

// Import pages
import Login from "./components/LoginForm/LoginForm.vue";
import Settings from "./pages/Settings.vue";
import NewMenu from "./pages/Menus/NewMenu.vue";
import Menus from "./pages/Menus/Menus.vue";
import DeleteMenus from "./pages/Menus/DeleteMenu.vue";
import MenuItem from "./pages/Menus/_id/index.vue";
import NewBank from "./pages/Banks/NewBank.vue";
import NewBankNumber from "./pages/BankNumber/NewBank.vue";
import BankNumber from "./pages/BankNumber/Banks.vue";
import BankNumberItem from "./pages/BankNumber/_id/index.vue";
import Banks from "./pages/Banks/Banks.vue";

import NewSlide from "./pages/Slides/NewSlide.vue";
import Slides from "./pages/Slides/Slides.vue";
import SlideItem from "./pages/Slides/_id/index.vue";

import NewGallery from "./pages/Gallery/NewGallery.vue";
import Gallery from "./pages/Gallery/Gallery.vue";
import GalleryItem from "./pages/Gallery/_id/index.vue";

import NewPhone from "./pages/Phones/NewPhone.vue";
import Phones from "./pages/Phones/Phones.vue";
import PhoneItem from "./pages/Phones/_id/index.vue";
import NewService from "./pages/Services/NewService.vue";
import Services from "./pages/Services/Services.vue";
import ServiceItem from "./pages/Services/_id/index.vue";
import NewServiceCompany from "./pages/ServicesCompany/NewService.vue";
import ServicesCompany from "./pages/ServicesCompany/Services.vue";
import ServiceItemCompany from "./pages/ServicesCompany/_id/index.vue";
import NewNews from "./pages/News/NewNews.vue";
import News from "./pages/News/News.vue";
import NewsItem from "./pages/News/_id/index.vue";
import NewListing from "./pages/Listings/NewListing.vue";
import Listings from "./pages/Listings/Listings.vue";
import ListingItem from "./pages/Listings/_id/index.vue";
import NewStaff from "./pages/Staffs/NewStaff.vue";
import Staffs from "./pages/Staffs/Staffs.vue";
import NewCorporate from "./pages/Corporate/NewCorporate.vue";
import Timeline from "./pages/Corporate/Timeline.vue";
import Management from "./pages/Corporate/Management.vue";
import Corporate from "./pages/Corporate/Corporate.vue";
import CorporateItem from "./pages/Corporate/_id/index.vue";
import NewGdpr from "./pages/Gdpr/NewGdpr.vue";
import Gdpr from "./pages/Gdpr/Gdpr.vue";
import GdprItem from "./pages/Gdpr/_id/index.vue";
import NewFair from "./pages/Fairs/NewFair.vue";
import Fairs from "./pages/Fairs/Fairs.vue";
import FairItem from "./pages/Fairs/_id/index.vue";
import NewPopup from "./pages/Popup/NewPopup.vue";

import Security from "./pages/Security/Security.vue";
import NewSecurity from "./pages/Security/NewSecurity.vue";
import SecurityItem from "./pages/Security/_id/index.vue";

import Company from "./pages/Company/Company.vue";
import NewCompany from "./pages/Company/NewCompany.vue";
import CompanyItem from "./pages/Company/_id/index.vue";

import Email from "./pages/Email/Email.vue";
import NewEmail from "./pages/Email/NewEmail.vue";
import EmailItem from "./pages/Email/_id/index.vue";
import Message from "./pages/Messages/Messages.vue";
import MessageItem from "./pages/Messages/_id/index.vue";

// CSS for layout
import "./assets/css/flexgrid.min.css";

// Create Vue Router
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/login",
            component: Login,
            props: true,
            meta: {
                requiresUnauth: true,
            },
        },
        {
            path: "/new-service",
            component: NewService,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/services",
            component: Services,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "service-detail",
            path: "/services/:id",
            component: ServiceItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/new-service-company",
            component: NewServiceCompany,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/services-company",
            component: ServicesCompany,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "service-company-detail",
            path: "/services/:id",
            component: ServiceItemCompany,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/new-fair",
            component: NewFair,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/fairs",
            component: Fairs,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "fair-detail",
            path: "/fairs/:id",
            component: FairItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/new-corporate",
            component: NewCorporate,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/timeline",
            component: Timeline,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/management",
            component: Management,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/corporate-items",
            component: Corporate,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            name: "corporate-detail",
            path: "/corporate/:id",
            component: CorporateItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/new-gdpr",
            component: NewGdpr,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/gdpr",
            component: Gdpr,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "gdpr-detail",
            path: "/gdpr/:id",
            component: GdprItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/new-news",
            component: NewNews,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/news",
            component: News,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "news-detail",
            path: "/news/:id",
            component: NewsItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/new-listing",
            component: NewListing,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/listings",
            component: Listings,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            name: "listing-detail",
            path: "/listings/:id",
            component: ListingItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/new-staff",
            component: NewStaff,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/staffs",
            component: Staffs,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/new-slide",
            component: NewSlide,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/slides",
            component: Slides,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            name: "slide-detail",
            path: "/slides/:id",
            component: SlideItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },




        {
            path: "/new-gallery",
            component: NewGallery,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/gallery",
            component: Gallery,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            name: "gallery-detail",
            path: "/gallery/:id",
            component: GalleryItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },



        {
            path: "/new-menu",
            component: NewMenu,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/menus",
            component: Menus,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/menus/delete-menu",
            component: DeleteMenus,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "menu-detail",
            path: "/menus/:id",
            component: MenuItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/new-bank",
            component: NewBank,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/banks",
            component: Banks,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/new-bank-number",
            component: NewBankNumber,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/bank-number",
            component: BankNumber,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "bank-number-detail",
            path: "/bank-number-detail/:id",
            component: BankNumberItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/new-phone",
            component: NewPhone,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/phones",
            component: Phones,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "phone-detail",
            path: "/phones/:id",
            component: PhoneItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/new-popup",
            component: NewPopup,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/settings",
            component: Settings,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/security",
            component: Security,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/new-security",
            component: NewSecurity,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "security-detail",
            path: "/security/:id",
            component: SecurityItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/company",
            component: Company,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/new-company",
            component: NewCompany,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "company-detail",
            path: "/company/:id",
            component: CompanyItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/email",
            component: Email,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/email-send",
            component: NewEmail,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "email-detail",
            path: "/email/:id",
            component: EmailItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/message",
            component: Message,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: "message-detail",
            path: "/message/:id",
            component: MessageItem,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
    ],
});

router.beforeEach(function(to, _, next) {
    if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
        next("/login");
    } else if (to.meta.requiresUnauth && store.getters.isLoggedIn) {
        next("/dashboard");
    } else {
        next();
    }
});

// Import base UI components
import BaseInput from "./components/UI/BaseInput.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import BasePageTitle from "./components/UI/BasePageTitle.vue";
import BaseSpinner from "./components/UI/BaseSpinner.vue";
import BaseNotification from "./components/UI/BaseNotification.vue";

const app = createApp(App);

// Use base UI components
app.use("base-input", BaseInput);
app.use("base-button", BaseButton);
app.use("base-button", BasePageTitle);
app.use("base-spinner", BaseSpinner);
app.use("base-notification", BaseNotification);

//Use Vuex
app.use(store);

// Use Vue Router
app.use(router);
app.use(CKEditor);

// Mount the app
app.mount("#app");
