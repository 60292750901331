<template>
  <section class="page-container">
     <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Şİrket Hesabı Ekle"></base-page-title>
        </div>
        <form ref="form" @submit.prevent="onSubmit" class="w-100">
          <div class="col-12">
            <base-input
              id="username"
              mode="text"
              class="mb-3"
              v-model="username"
              label="Kullanıcı Adı"
            >
            </base-input>

            <base-input
              id="password"
              mode="text"
              class="mb-3"
              v-model="password"
              label="Şifre"
            >
            </base-input>

            <select v-model="selected">
              <option value="{}"> Seçiniz... </option>
              <option 
                v-for="(item, index) in companyList"
                :key="index"
                :value="item"
              >  {{ item.CARİ_KOD }} </option>
            </select>

            <div style="margin-top: 20px; margin-bottom: 20px" v-show="Object.keys(selected).length"> 
               <span>
                  <b>Firma:</b> {{ selected.CARİ_KOD }} <br>
              </span>
              <span>
                  <b>İl:</b> {{ selected.İL }} <br>
              </span>
              <span>
                  <b>İlçe:</b> {{ selected.İLÇE }} <br>
              </span>
              <span>
                  <b>Adres:</b> {{ selected.ADRES }} <br>
              </span>
              <span>
                  <b>Fax Kodu:</b> {{ selected.FAX_CODE }} <br>
              </span>
              <span>
                  <b>Fax No:</b> {{ selected.FAX_NO }} <br>
              </span>
              <span>
                  <b>GSM No:</b> {{ selected.GSM_NO }} <br>
              </span>
              <span>
                  <b>E-Posta:</b> {{ selected.MAIL_ADRES }} <br>
              </span>
               <span>
                  <b>Özel Kod:</b> {{ selected.OZEL_KOD }} <br>
              </span>
               <span>
                  <b>Telefon Numarası:</b> {{ selected.TELEFON_NO }} <br>
              </span>
               <span>
                  <b>Telefon Kodu:</b> {{ selected.TEL_KOD }} <br>
              </span>
               <span>
                  <b>Web Adresi:</b> {{ selected.WEADRES }} <br>
              </span>
            </div>
            <base-button class="mt-3" value="Kaydet"></base-button>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Şirket Eklendi"
    >
    </base-notification>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseButton from "../../components/UI/BaseButton";
import BaseInput from "../../components/UI/BaseInput";
import BaseNotification from "../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseButton,
    BasePageTitle,
    BaseInput,
    BaseNotification,
  },

  data() {
    return {
      password: null,
      username: null,
      selected: {},
      itemSuccess: false,
    };
  },
  computed: {
    ...mapGetters({
      token: "token",
    }),

    companyList() {
      return this.$store.getters["companyList"];
    },
   
  },
  async mounted() {
     await this.$store.dispatch("fetchCompanyList");
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      const formData = {};
      formData.password = this.password;
      formData.username = this.username;
      formData.token = this.token;
      formData.companyId = this.selected.CODE;

      await axios
        .post("https://api.dessanayi.org/admin/company/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
        });
      this.isLoading = false;

      Array.from(document.querySelectorAll("label")).map((e) => {
        e.classList.remove("clicked-input");
      });
    },
  },
};
</script>

<style></style>
