<template>
  <section class="page-container">
     <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Güvenlik Personeli Ekle"></base-page-title>
        </div>
        <form ref="form" @submit.prevent="onSubmit" class="w-100">
          <div class="col-12">
            <base-input
              id="title"
              mode="text"
              class="mb-3"
              v-model="fullname"
              label="Adı Soyadı"
            >
            </base-input>

            <base-input
              id="slug"
              mode="email"
              class="mb-3"
              v-model="email"
              label="E-Posta"
            >
            </base-input>

             <base-input
              id="slug"
              mode="text"
              class="mb-3"
              v-model="phone"
              label="Telefon"
            >
            </base-input>

             <base-input
              id="slug"
              mode="text"
              class="mb-3"
              v-model="username"
              label="Kullanıcı Adı"
            >
            </base-input>
             <base-input
              id="slug"
              mode="text"
              class="mb-3"
              v-model="password"
              label="Şifre"
            >
            </base-input>
            <base-button class="mt-3" value="Kaydet"></base-button>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Personel Eklendi"
    >
    </base-notification>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseButton from "../../components/UI/BaseButton";
import BaseInput from "../../components/UI/BaseInput";
import BaseNotification from "../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseButton,
    BasePageTitle,
    BaseInput,
    BaseNotification,
  },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      fullname: null,
      email: null,
      password: null,
      phone: null,
      username: null,
    };
  },
  computed: {
    ...mapGetters({
      token: "token",
    }),
   
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      const formData = {};
      formData.fullname = this.fullname;
      formData.email = this.email;
      formData.description = this.description;
      formData.password = this.password;
      formData.phone = this.phone;
      formData.username = this.username;
      formData.token = this.token;

      await axios
        .post("https://api.dessanayi.org/admin/security/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
        });
      this.isLoading = false;

      Array.from(document.querySelectorAll("label")).map((e) => {
        e.classList.remove("clicked-input");
      });
    },
  },
};
</script>

<style></style>
