export default {
  async fetchFair(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    data.append(`pageType`, "FAIR");
    const response = await fetch(`https://api.dessanayi.org/admin/page/`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    const responseData = await response.json();

    const fairs = [];

    for (const key in responseData.data) {
      const fair = {
        id: responseData.data[key]._id,
        title: responseData.data[key].title,
        editorData: responseData.data[key].content,
        slug: responseData.data[key].url,
        keyword: responseData.data[key].keyword,
        description: responseData.data[key].description,
      };
      fairs.push(fair);
    }

    context.commit("fetchFair", fairs);

    console.log(token, responseData);
  },
};
