<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Galeri"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="(gallery, index) in galleries"
          :key="gallery.id"
          class="col-lg-3 col-12 mb-4"
        >
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-img is-equal-image">
              <router-link
                :to="{
                  name: 'gallery-detail',
                  params: {
                    id: gallery._id,
                    parentSlug: gallery.slug,
                    parentTitle: gallery.title,
                    parentImage: gallery.image,
                    parentUrl: gallery.url,
                    parentOrder: gallery.order,
                  },
                }"
              >
                <img
                  :src="'https://api.dessanayi.org/image/gallery/' + gallery.image"
                  :alt="gallery.title"
                />
              </router-link>
            </div>
            <span
              class="remove-item"
              :galleryId="gallery._id"
              :index="index"
              @click="removeGallery"
            >
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePageTitle,
    BaseSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    galleries() {
      return this.$store.getters["galleries"];
    },
    ...mapGetters({
      token: "token",
    }),
  },
  mounted() {
    this.fetchGallery();
  },
  methods: {
    removeGallery(event) {
      const galleryId = event.target.getAttribute("galleryId");
      const index = event.target.getAttribute("index");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/gallery/remove/" + galleryId,
        formData,
        {}
      );
      this.galleries.splice(index, 1);
    },

    async fetchGallery() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchGallery");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
