export default {
  async fetchBankNumber(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(
      `https://api.dessanayi.org/admin/config/bank-number`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    const responseData = await response.json();

    context.commit("fetchBankNumber", responseData.data);

    // console.log(responseData);
  },

  async postBankNumber(context, payload) {
    const token = context.rootGetters.token;
    let data = {
      title: payload.bankName,
      visible: true,
      image: payload.image,
    };

    data = new URLSearchParams(data).toString();

    // console.log(data, payload);
    data.append(`token`, token);
    const response = await fetch(
      `https://api.dessanayi.org/admin/config/bank-number/add`,
      {
        method: "POST",
        body: data,
      }
    );
    const responseData = await response.json();

    console.log("postBankNumber", responseData, data);
  },
};
