export default {
  async fetchStaff(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    data.append(`pageType`, "STAFF");
    const response = await fetch(`https://api.dessanayi.org/admin/page `, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const responseData = await response.json();

    const staffs = [];

    for (const key in responseData.data) {
      const staff = {
        id: responseData.data[key]._id,
        title: responseData.data[key].title,
        url: responseData.data[key].url,
        image: responseData.data[key].image,
      };
      staffs.push(staff);
    }

    context.commit("fetchStaff", staffs);

    console.log(token, responseData);
  },
};
