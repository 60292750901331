<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="İş Yeri İşlemi Ekle"></base-page-title>
        </div>
        <form @submit.prevent="onSubmit" class="w-100 d-flex flex-wrap">
          <div class="col-12 mb-4">
            <div class="image-upload-box">
              <label for="activity-icon">İş Yeri İşlemi İkonu</label><br />
              <input type="file" id="activity-icon" @change="onFileUpload" />
            </div>
          </div>
          <div class="col-xl-10 col-12">
            <base-input
              id="title"
              mode="text"
              class="mb-3"
              v-model="title"
              label="Sayfa Başlığı"
            >
            </base-input>
          </div>
          <div class="col-xl-2 col-12">
            <base-input
              id="order"
              mode="text"
              class="mb-2"
              v-model="order"
              label="Hizmet Sırası"
            >
            </base-input>
          </div>
          <div class="col-12">
            <base-input
              id="slug"
              mode="text"
              class="mb-3"
              v-model="slug"
              label="Sayfa URL eki"
            >
            </base-input>
            <ckeditor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"
            >
            </ckeditor>
            <dashboard
              ref="dash"
              :uppy="uppy"
              :props="{
                metaFields: [
                  { id: 'name', name: 'Name', placeholder: 'file name' },
                  {
                    id: 'caption',
                    name: 'Caption',
                    placeholder: 'describe what the image is about',
                  },
                ],
              }"
              :plugins="['ImageEditor']"
            />
            <div>
              <ul v-for="file in this.files" :key="file">
                <li>
                  {{ file }}
                  <label
                    @click="removeFile"
                    :fileValue="file"
                    style="color: red; cursor: pointer"
                  >
                    (kaldır)
                  </label>
                </li>
              </ul>
            </div>
            <base-button class="mt-3" value="Kaydet"> </base-button>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Faaliyet Eklendi"
    >
    </base-notification>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";
import UploadAdapter from "../../../Shared/UploadAdapter";
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseButton from "../../components/UI/BaseButton";
import BaseInput from "../../components/UI/BaseInput";
import BaseNotification from "../../components/UI/BaseNotification";

import axios from "axios";
import { mapGetters } from "vuex";

import { Dashboard } from "@uppy/vue";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
const uppy = new Uppy({
  meta: {
    token: "asddasd",
  },
});
uppy.use(XHRUpload, {
  endpoint: "https://api.dessanayi.org/admin/file/",
  fieldName: "files",
  formData: true,
});
export default {
  components: {
    BaseButton,
    BasePageTitle,
    BaseInput,
    BaseNotification,
    Dashboard,
  },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      editor: ClassicEditor,
      title: null,
      icon: null,
      editorData: null,
      files: [],
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "imageUpload",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        extraPlugins: [this.uploader],
        language: "tr",
      },
    };
  },
  computed: {
    ...mapGetters({
      token: "token",
    }),
    uppy: () =>
      new Uppy({
        meta: {
          token: localStorage.token,
        },
      }).use(XHRUpload, {
        endpoint: "https://api.dessanayi.org/admin/file/",
        fieldName: "file",
      }),
  },
  beforeUnmount() {
    this.uppy.close();
  },
  mounted() {
    this.uppy.on("upload-success", (file, response) => {
      response = response.body;
      if (response.status == "success") {
        this.files.push(response.data.name);
      }
      console.log(this.files);
    });
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },

    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },

    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("keyword", this.keyword);
      formData.append("content", this.editorData);
      formData.append("visible", "on");
      formData.append("url", this.slug);
      formData.append("icon", this.FILE);
      formData.append("order", this.order);
      formData.append("file", JSON.stringify(this.files));
      formData.append("pageType", "SERVICES-COMPANY");

      await axios
        .post("https://api.dessanayi.org/admin/page/add", formData, {})
        .then(() => {
          this.itemSuccess = true;
          this.title = "";
          this.editorData = "";
        });
      this.isLoading = false;

      Array.from(document.querySelectorAll("label")).map((e) => {
        e.classList.remove("clicked-input");
      });
    },
  },
};
</script>

<style></style>
