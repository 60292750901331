<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Slaytlar"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="(slide, index) in slides"
          :key="slide.id"
          class="col-lg-3 col-12 mb-4"
        >
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-img is-equal-image">
              <router-link
                :to="{
                  name: 'slide-detail',
                  params: {
                    id: slide.id,
                    parentSlug: slide.slug,
                    parentTitle: slide.title,
                    parentImage: slide.image,
                    parentUrl: slide.url,
                    parentOrder: slide.order,
                  },
                }"
              >
                <img
                  :src="'https://api.dessanayi.org/image/slide/' + slide.image"
                  :alt="slide.title"
                />
              </router-link>
            </div>
            <span
              class="remove-item"
              :slideId="slide.id"
              :index="index"
              @click="removeSlide"
            >
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePageTitle,
    BaseSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    slides() {
      return this.$store.getters["slides"];
    },
    ...mapGetters({
      token: "token",
    }),
  },
  mounted() {
    this.fetchSlides();
  },
  methods: {
    removeSlide(event) {
      const slideId = event.target.getAttribute("slideId");
      const index = event.target.getAttribute("index");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/slide/remove/" + slideId,
        formData,
        {}
      );
      this.slides.splice(index, 1);
    },

    async fetchSlides() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchSlide");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
