<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="KVKK"></base-page-title>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="gdpr_item in gdpr_items"
          :key="gdpr_item.id"
          class="col-lg-3 col-12 mb-3"
        >
          <div v-if="!isLoading" class="item text-center p-4">
            <div class="item-name">
              <router-link
                :to="{
                  name: 'gdpr-detail',
                  params: {
                    id: gdpr_item.id,
                    parentSlug: gdpr_item.url,
                    parentTitle: gdpr_item.title,
                    parentEditorData: gdpr_item.content,
                    parentFiles: gdpr_item.files,
                  },
                }"
              >
                {{ gdpr_item.title }}
              </router-link>
            </div>
            <span
              class="remove-item"
              :gdprId="gdpr_item.id"
              @click="removeGdprItem"
            >
              Sil
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePageTitle,
    BaseSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    gdpr_items() {
      return this.$store.getters["gdpr_items"];
    },
    ...mapGetters({
      token: "token",
    }),
  },
  mounted() {
    this.fetchGdprItems();
  },
  methods: {
    removeGdprItem(event) {
      const gdprId = event.target.getAttribute("gdprId");
      const formData = new FormData();
      formData.append("token", this.token);
      axios.post(
        "https://api.dessanayi.org/admin/page/remove/" + gdprId,
        formData,
        {}
      );
    },

    async fetchGdprItems() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchGdprItem");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
